import React from 'react';
import PropTypes from 'prop-types';

import { OPTION_GROUP_VARIANTS } from 'dpl/quiz/option_group/utils/constants';

import RadioQuestion from './RadioQuestion';

const BOOLEAN_OPTIONS = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

export default function BooleanQuestion({
  name,
  title,
  subtitle,
  variant,
  subtitleIcon
}) {
  return (
    <div className="BooleanQuestion">
      <RadioQuestion
        name={name}
        title={title}
        subtitle={subtitle}
        subtitleIcon={subtitleIcon}
        variant={variant}
        isListOnMobile={false}
        options={BOOLEAN_OPTIONS}
      />
    </div>
  );
}

BooleanQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleIcon: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(OPTION_GROUP_VARIANTS))
};

BooleanQuestion.defaultProps = {
  title: null,
  subtitle: null,
  subtitleIcon: null,
  variant: OPTION_GROUP_VARIANTS.CIRCLE
};
