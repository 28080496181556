import React from 'react';
import PropTypes from 'prop-types';

import ListOption from './ListOption';

export default function ListOptionWithTitle({
  title,
  subtitle,
  imageComponent,
  ...optionProps
}) {
  return (
    <div className="QuizOptionGroupListOptionWithTitle">
      <ListOption {...optionProps}>
        <div className="d-md-none">{imageComponent}</div>
        <div className="flex-auto tl ph4">
          <p className="fw-medium break-word">{title}</p>
          {subtitle && <p className="break-word">{subtitle}</p>}
        </div>
      </ListOption>
    </div>
  );
}

ListOptionWithTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  imageComponent: PropTypes.node
};

ListOptionWithTitle.defaultProps = {
  imageComponent: null,
  subtitle: null
};
