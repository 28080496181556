import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SmartImage from 'dpl/components/SmartImage';
import QuizIcon from 'dpl/quiz/components/Icon';

import { defaultGetQuestionComponent } from '../utils';

export default function SectionLayout({
  icon,
  image,
  title,
  subtitle,
  questions,
  onNextClick,
  onQuizSubmit,
  getQuestionComponent,
  canAdvance,
  isLastSection,
  questionWrapperClasses,
  questionClasses,
  isCurrentSection
}) {
  return (
    <div className="SectionLayout w-100 pv4 ph5">
      <div className="SectionLayout__top tc center">
        {(image || icon) && (
          <div className="mb6 mb8-md f0">
            {image?.src && <SmartImage lazy {...image} />}
            {icon && <QuizIcon name={icon} />}
          </div>
        )}

        {(title || subtitle) && (
          <div className="mb8 mb14-md tc">
            {title && (
              <h3
                className="f4 fw-medium f5-md"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}

            {subtitle && (
              <p className="mt2 mt4-md font-16 secondary">{subtitle}</p>
            )}
          </div>
        )}
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();

          if (!canAdvance) {
            return;
          }

          if (isLastSection) {
            onQuizSubmit();
          } else {
            onNextClick(e);
          }
        }}
      >
        <div className={questionWrapperClasses}>
          {questions.map((question, idx) => {
            const QuestionComponent = getQuestionComponent(question);
            return (
              <div
                key={question.input_name}
                className={classnames(
                  'SectionLayout__quizQuestion',
                  `SectionLayout__quizQuestion--${question.input_name}`,
                  { 'mb4 mr6-sm': idx !== questions.length - 1 },
                  questionClasses
                )}
              >
                <QuestionComponent
                  title={question.title}
                  subtitle={question.subtitle}
                  name={question.input_name}
                  options={question.options}
                  isCurrentSection={isCurrentSection}
                  {...question.component_props}
                />
              </div>
            );
          })}
        </div>
        <button
          type="submit"
          disabled={!canAdvance}
          className="SectionButton d-sm-flex d-none pv3 mt8 mt14-md mb2 w-100 inline-flex justify-center button fw-medium items-center center nowrap bg-blue-500 white b--blue-500 font-16 br2"
        >
          {isLastSection ? 'Submit' : 'Continue'}
        </button>
      </form>
    </div>
  );
}

SectionLayout.propTypes = {
  icon: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired
  }),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string
    })
  ).isRequired,
  onNextClick: PropTypes.func.isRequired,
  onQuizSubmit: PropTypes.func.isRequired,
  getQuestionComponent: PropTypes.func,
  canAdvance: PropTypes.bool.isRequired,
  isLastSection: PropTypes.bool.isRequired,
  questionWrapperClasses: PropTypes.string,
  questionClasses: PropTypes.string,
  isCurrentSection: PropTypes.bool
};

SectionLayout.defaultProps = {
  icon: null,
  image: null,
  title: null,
  subtitle: null,
  getQuestionComponent: defaultGetQuestionComponent,
  questionWrapperClasses: '',
  questionClasses: '',
  isCurrentSection: false
};
