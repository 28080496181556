import React from 'react';
import PropTypes from 'prop-types';

import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import OptionGroup from 'dpl/quiz/option_group/components/OptionGroup';
import OptionGroupOption from 'dpl/quiz/option_group/components/OptionGroupOption';
import { OPTION_GROUP_VARIANTS } from 'dpl/quiz/option_group/utils/constants';

import QuestionLayout from '../../components/QuestionLayout';

export default function RadioQuestion({
  name,
  title,
  subtitle,
  options,
  variant,
  isListOnMobile,
  subtitleIcon
}) {
  const { setValue, formFieldValue } = useFormContext(name);

  return (
    <div className="RadioQuestion">
      <QuestionLayout
        title={title}
        subtitle={subtitle}
        subtitleIcon={subtitleIcon}
      />
      <OptionGroup variant={variant} isListOnMobile={isListOnMobile}>
        {options.map(o => (
          <OptionGroupOption
            type="radio"
            key={o.value}
            inputName={name}
            isSelected={formFieldValue === o.value}
            title={o.label}
            subtitle={o.description}
            icon={o.icon}
            value={o.value}
            onSelect={() => setValue(name, o.value)}
          />
        ))}
      </OptionGroup>
    </div>
  );
}

RadioQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  variant: PropTypes.oneOf(Object.values(OPTION_GROUP_VARIANTS)),
  isListOnMobile: PropTypes.bool,
  subtitleIcon: PropTypes.string
};

RadioQuestion.defaultProps = {
  title: null,
  subtitle: null,
  variant: OPTION_GROUP_VARIANTS.CIRCLE,
  isListOnMobile: true,
  subtitleIcon: null
};
