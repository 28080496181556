export const ICON_NAMES = [
  'checkheart',
  'coins',
  'future',
  'gender-female',
  'gender-male',
  'house',
  'incoming-call',
  'location',
  'shield',
  'socialize',
  'stethoscope',
  'transportation-airplane-take-off',
  'transportation-flight-seat',
  'transportation-airport',
  'transportation-driver',
  'vet',
  'my-home',
  'guardian-home',
  'kennel',
  'attachment',
  'phone'
];
