import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'dpl/common/components/Icon';

export default function QuestionLayout({
  title,
  subtitle,
  children,
  subtitleIcon
}) {
  return (
    <div className="QuestionLayout center">
      {(title || subtitle) && (
        <div className="mb8 tc">
          {title && <h3 className="f4 fw-medium f5-md">{title}</h3>}
          {subtitle && (
            <p className="mt2 mt4-md font-16 secondary">
              {subtitleIcon && (
                <span className="v-sub mr2">
                  <Icon name={subtitleIcon} className="dib" />
                </span>
              )}
              <span>{subtitle}</span>
            </p>
          )}
        </div>
      )}
      {children}
    </div>
  );
}

QuestionLayout.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  subtitleIcon: PropTypes.string
};

QuestionLayout.defaultProps = {
  title: null,
  subtitle: null,
  children: null,
  subtitleIcon: null
};
