import React from 'react';
import PropTypes from 'prop-types';

import Textarea from 'dpl/form/components/Textarea';
import { FORM_FIELD_VARIANT_TYPES } from 'dpl/shared/form/utils/constants';

import QuestionLayout from '../../components/QuestionLayout';

export default function TextareaQuestion({
  name,
  title,
  subtitle,
  label,
  placeholder,
  noResize,
  variant,
  subtitleIcon
}) {
  return (
    <div className="TextareaQuestion font-16">
      <QuestionLayout
        title={title}
        subtitle={subtitle}
        subtitleIcon={subtitleIcon}
      >
        <Textarea
          name={name}
          label={label}
          variant={variant}
          placeholder={placeholder}
          noResize={noResize}
          newDesignSystemStyles
        />
      </QuestionLayout>
    </div>
  );
}

TextareaQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  noResize: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(FORM_FIELD_VARIANT_TYPES)),
  subtitleIcon: PropTypes.string
};

TextareaQuestion.defaultProps = {
  title: null,
  subtitle: null,
  label: null,
  placeholder: null,
  noResize: false,
  variant: FORM_FIELD_VARIANT_TYPES.REGULAR,
  subtitleIcon: null
};
