/**
 * This component is meant to be a temporary copy of the SimpleInput.
 * Hopefully this one can be removed and input prefix support can be added there.
 * Currently there are issues with supporting the <ErrorWrapper>.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  COMMON_FORM_FIELD_CLASSES,
  FORM_FIELD_VARIANT_TYPES
} from 'dpl/shared/form/utils/constants';

import Label from './Label';
import ErrorWrapper from './ErrorWrapper';

export const VARIANT_CLASSES = {
  [FORM_FIELD_VARIANT_TYPES.LARGE]: `${COMMON_FORM_FIELD_CLASSES} pv4 ph4 pv5-md fw-medium`,
  [FORM_FIELD_VARIANT_TYPES.REGULAR]: `${COMMON_FORM_FIELD_CLASSES} pv2 ph2`
};

const InstagramHandleInput = React.forwardRef(
  (
    {
      autoComplete,
      displayErrors,
      errors,
      id,
      label,
      name,
      onChange,
      required,
      value,
      variant,
      ...inputProps
    },
    ref
  ) => (
    <div className="InstagramHandleInput">
      {label && <Label htmlFor={id} required={required} text={label} />}
      <ErrorWrapper isShown={displayErrors} errors={errors}>
        <div
          className={classnames(
            'InstagramHandleInput__input flex',
            VARIANT_CLASSES[variant]
          )}
        >
          <span className="ph2 no-select">@</span>
          <input
            // autoComplete "off" does not prevent the user from autofilling but "nope" does
            autoComplete={autoComplete === 'off' ? 'nope' : autoComplete}
            className="input-reset pr4 flex-auto bn w-100"
            data-test-id="form-input"
            id={id}
            name={name}
            onChange={onChange}
            value={value ?? ''}
            {...inputProps}
            ref={ref}
          />
        </div>
      </ErrorWrapper>
    </div>
  )
);

InstagramHandleInput.displayName = 'InstagramHandleInput';

InstagramHandleInput.propTypes = {
  autoComplete: PropTypes.string,
  displayErrors: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.node),
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  prefix: PropTypes.node,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** One of: "regular" or "large". */
  variant: PropTypes.oneOf(Object.values(FORM_FIELD_VARIANT_TYPES))
};

InstagramHandleInput.defaultProps = {
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#Values
  autoComplete: null,
  displayErrors: false,
  errors: [],
  id: null,
  label: null,
  prefix: null,
  required: false,
  type: 'text',
  value: null,
  variant: FORM_FIELD_VARIANT_TYPES.REGULAR
};

export default InstagramHandleInput;
