import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'dpl/common/components/Icon';

export default function Checkbox({
  checked,
  id,
  name,
  onChange,
  title,
  value,
  dataTestId,
  disabled
}) {
  return (
    <div className="Checkbox">
      <label className="flex items-center" htmlFor={id}>
        <CheckboxInput
          checked={checked}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          dataTestId={dataTestId}
          disabled={disabled}
        />
        {title && <span className="pl2 flex-auto">{title}</span>}
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool
};

Checkbox.defaultProps = {
  title: null,
  disabled: false,
  dataTestId: null
};

export function CheckboxInput({
  checked,
  id,
  name,
  onChange,
  value,
  disabled,
  dataTestId
}) {
  const iconName = checked ? 'checkbox-on' : 'checkbox-off';

  return (
    <div className="CheckboxInput dib" data-test-id={dataTestId}>
      <input
        className="Checkbox__input"
        checked={checked}
        id={id}
        name={name}
        onChange={() => {}}
        onClick={onChange}
        type="checkbox"
        value={value}
        disabled={disabled}
      />
      <Icon name={iconName} className={classnames({ 'moon-gray': disabled })} />
    </div>
  );
}

CheckboxInput.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string
};

CheckboxInput.defaultProps = {
  disabled: false,
  dataTestId: 'CheckboxInput'
};
