export const OPTION_GROUP_VARIANTS = {
  LIST: 'list',
  CIRCLE: 'circle',
  RECTANGLE: 'rectangle',
  ICON: 'icon',
  PILL: 'pill'
};

export const BOOLEAN_OPTIONS = [
  {
    title: 'Yes',
    value: true
  },
  {
    title: 'No',
    value: false
  }
];
