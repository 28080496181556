import React from 'react';
import PropTypes from 'prop-types';

import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import OptionGroup from 'dpl/quiz/option_group/components/OptionGroup';
import OptionGroupOption from 'dpl/quiz/option_group/components/OptionGroupOption';
import { OPTION_GROUP_VARIANTS } from 'dpl/quiz/option_group/utils/constants';
import TagSelector from 'dpl/components/TagSelector';
import { difference } from 'dpl/shared/utils';

import QuestionLayout from '../../components/QuestionLayout';

export default function CheckboxListWithTagSelectorQuestion({
  name,
  title,
  subtitle,
  options,
  placeholder
}) {
  const { setValue, formFieldValue } = useFormContext(name);
  const finalFormFieldValue = formFieldValue || [];
  const selectedValuesSet = new Set(finalFormFieldValue);
  const tagSelectorSelectedValues = difference(
    finalFormFieldValue,
    options.checklist_options.map(o => o.value)
  );

  function handleOptionChange(value, isChecked) {
    if (isChecked) {
      selectedValuesSet.add(value);
    } else {
      selectedValuesSet.delete(value);
    }

    setValue(name, [...selectedValuesSet]);
  }

  return (
    <div className="CheckboxListWithTagSelectorQuestion">
      <QuestionLayout title={title} subtitle={subtitle}>
        <div className="mb3">
          <OptionGroup variant={OPTION_GROUP_VARIANTS.LIST}>
            {options.checklist_options.map(o => (
              <OptionGroupOption
                type="checkbox"
                key={o.value}
                inputName={name}
                isSelected={selectedValuesSet.has(o.value)}
                title={o.label}
                subtitle={o.description}
                icon={o.icon}
                value={o.value}
                onSelect={e => handleOptionChange(o.value, e.target.checked)}
              />
            ))}
          </OptionGroup>
        </div>
        <TagSelector
          placeholder={placeholder}
          id={`${name}_tag_selector`}
          selectedValues={tagSelectorSelectedValues}
          availableTags={options.tag_selector_options.map(o => ({
            title: o.label,
            value: o.value
          }))}
          onValueSelect={val => handleOptionChange(val, true)}
          onValueRemove={val => handleOptionChange(val, false)}
        />
      </QuestionLayout>
    </div>
  );
}

CheckboxListWithTagSelectorQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  options: PropTypes.shape({
    checklist_options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.string,
          PropTypes.number
        ]).isRequired
      })
    ).isRequired,
    tag_selector_options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.string,
          PropTypes.number
        ]).isRequired
      })
    ).isRequired
  }).isRequired,
  placeholder: PropTypes.string
};

CheckboxListWithTagSelectorQuestion.defaultProps = {
  title: null,
  subtitle: null,
  placeholder: null
};
