import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'dpl/quiz/components/Icon';

export default function IconOption({
  icon,
  value,
  onSelect,
  type,
  inputName,
  isSelected,
  title,
  subtitle
}) {
  const htmlId = `${inputName}-${value}`;

  return (
    <div className="QuizOptionGroupIconOption mb6">
      <label
        className={classnames(
          'QuizOptionGroupIconOption__option db transition ph6-lg ph3 pv8 br1',
          {
            'QuizOptionGroupIconOption__option--selected': isSelected
          }
        )}
        htmlFor={htmlId}
      >
        <input
          checked={isSelected}
          className="Checkbox__input--custom dn outline-0"
          id={htmlId}
          name={inputName}
          onClick={onSelect}
          onChange={() => {}}
          type={type}
          value={value}
        />
        <Icon name={icon} className="mb3" />
        <p className="f4-lg f3 fw-medium">{title}</p>
        {subtitle && <p>{subtitle}</p>}
      </label>
    </div>
  );
}

IconOption.propTypes = {
  icon: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['radio', 'checkbox']),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  inputName: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool
};

IconOption.defaultProps = {
  type: 'radio',
  subtitle: null,
  isSelected: false
};
