import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function PillOption({
  value,
  onSelect,
  type,
  inputName,
  isSelected,
  title,
  subtitle
}) {
  const htmlId = `${inputName}-${value}`;

  return (
    <div className="QuizOptionGroupPillOption mb2 mr2">
      <label
        className={classnames(
          'QuizOptionGroupPillOption__option db transition ba ph4 pv3 br3 max-content-h bg-white',
          {
            'stone-700 b--stone-700': isSelected,
            'secondary b--default': !isSelected
          }
        )}
        htmlFor={htmlId}
      >
        <input
          checked={isSelected}
          className="Checkbox__input--custom dn outline-0"
          id={htmlId}
          name={inputName}
          onClick={onSelect}
          onChange={() => {}}
          type={type}
          value={value}
        />
        <p className="font-16">{title}</p>
        {subtitle && <p className="font-12">{subtitle}</p>}
      </label>
    </div>
  );
}

PillOption.propTypes = {
  type: PropTypes.oneOf(['radio', 'checkbox']),
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  inputName: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  subtitle: PropTypes.string
};

PillOption.defaultProps = {
  type: 'radio',
  isSelected: false,
  subtitle: null
};
