import React from 'react';
import PropTypes from 'prop-types';

import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import OptionGroup from 'dpl/quiz/option_group/components/OptionGroup';
import OptionGroupOption from 'dpl/quiz/option_group/components/OptionGroupOption';
import { OPTION_GROUP_VARIANTS } from 'dpl/quiz/option_group/utils/constants';

import QuestionLayout from '../../components/QuestionLayout';

export default function CheckboxQuestion({
  name,
  title,
  subtitle,
  options,
  variant,
  isListOnMobile
}) {
  const { setValue, formFieldValue } = useFormContext(name);

  const finalFormFieldValue = formFieldValue || [];
  const selectedValuesSet = new Set(finalFormFieldValue);

  function handleOptionSelect(value, isChecked) {
    if (isChecked) {
      selectedValuesSet.add(value);
    } else {
      selectedValuesSet.delete(value);
    }

    setValue(name, [...selectedValuesSet]);
  }

  return (
    <div className="CheckboxQuestion">
      <QuestionLayout title={title} subtitle={subtitle} />
      <OptionGroup variant={variant} isListOnMobile={isListOnMobile}>
        {options.map(o => (
          <OptionGroupOption
            type="checkbox"
            key={o.value}
            inputName={name}
            isSelected={selectedValuesSet.has(o.value)}
            title={o.label}
            subtitle={o.description}
            icon={o.icon}
            value={o.value}
            onSelect={e => handleOptionSelect(o.value, e.target.checked)}
          />
        ))}
      </OptionGroup>
    </div>
  );
}

CheckboxQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  variant: PropTypes.oneOf(Object.values(OPTION_GROUP_VARIANTS)),
  isListOnMobile: PropTypes.bool
};

CheckboxQuestion.defaultProps = {
  title: null,
  subtitle: null,
  variant: OPTION_GROUP_VARIANTS.CIRCLE,
  isListOnMobile: true
};
