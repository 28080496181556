import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import SmartImage from 'dpl/components/SmartImage';
import DogPlaceholderSvg from 'dpl/common/components/DogPlaceholderSvg';
import ClickableDiv from 'dpl/common/components/ClickableDiv';
import Badge from 'dpl/common/design_system/Badge';

export default function PuppySelectorQuestion({ name, options }) {
  const { setValue, formFieldValue } = useFormContext(name);

  function isOptionSelected(opt) {
    return opt.value === formFieldValue;
  }

  function setSelectedPuppy(dogId) {
    setValue(name, dogId);
  }

  return (
    <div className="PuppySelectorQuestion QuestionLayout">
      <ul className="flex flex-wrap justify-center tl gap-8">
        {options.map(option => (
          <li
            key={option.value}
            data-test-id={option.value}
            className="PuppySelectorQuestion__option"
          >
            <ClickableDiv
              className={classnames(
                'h-100 w-100 hover-b--midnight db bg-white ba br4 box-shadow-hover',
                isOptionSelected(option)
                  ? 'b--stone-700 stone-700'
                  : 'b--default'
              )}
              onClick={() => setSelectedPuppy(option.value)}
            >
              <div className="aspect-ratio aspect-ratio--8x5 z-0">
                <div className="aspect-ratio--object">
                  <div className="h-100 w-100 overflow-hidden f0">
                    {option.profile_photo_url ? (
                      <SmartImage
                        Tag="div"
                        className="w-100 h-100 br--top br4 bg-center cover"
                        src={option.profile_photo_url}
                        lazy
                        crop
                      />
                    ) : (
                      <DogPlaceholderSvg
                        className="w-100 h-100 bb b--near-white bw1"
                        puppy
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="pv3 ph4">
                <p className="font-16 fw-medium mb1">{option.label}</p>
                {option.subtext && (
                  <p className="font-14 secondary mb2">{option.subtext}</p>
                )}
                {typeof option.available === 'boolean' && (
                  <Badge
                    color={option.available ? 'stroll' : 'rubber'}
                    size="small"
                  >
                    {option.available ? 'Available' : 'Reserved'}
                  </Badge>
                )}
              </div>
            </ClickableDiv>
          </li>
        ))}
      </ul>
    </div>
  );
}

PuppySelectorQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      available: PropTypes.bool,
      subtext: PropTypes.string,
      profile_photo_url: PropTypes.string
    })
  ).isRequired
};
