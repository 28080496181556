import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'dpl/common/design_system/Checkbox';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';

const CHECKBOX_LABEL =
  'I understand all payments must be made securely through Good Dog';

export default function PaymentPolicyAttestationQuestion({ name }) {
  const { setValue, formFieldValue } = useFormContext(name);

  return (
    <div className="PaymentPolicyAttestionQuestion center items-center fw-medium tl">
      <div className="bg-white pv4 ph4 br12 mb8">
        <Checkbox
          id={name}
          name={name}
          checked={Boolean(formFieldValue)}
          onChange={() => {
            setValue(name, !formFieldValue);
          }}
          label={CHECKBOX_LABEL}
        />
      </div>
    </div>
  );
}

PaymentPolicyAttestationQuestion.propTypes = {
  name: PropTypes.string
};

PaymentPolicyAttestationQuestion.defaultProps = {
  name: 'payment_policy_attestation_agreement'
};
