import React from 'react';
import PropTypes from 'prop-types';

import TelephoneInput from 'dpl/form/components/TelephoneInput';

export default function TelephoneQuestion({ name, placeholder }) {
  return (
    <div className="TelephoneInput center">
      <div className="items-center fw-medium tl">
        <TelephoneInput
          inputClassName="w-100 input-reset font-16 secondary pv3 pv4-md ph4 br2 ba b--moon-gray mb1"
          id={name}
          name={name}
          placeholder={placeholder}
          fullWidth
          label="Phone number"
          newDesignSystemStyles
        />
      </div>
    </div>
  );
}

TelephoneQuestion.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string
};

TelephoneQuestion.defaultProps = {
  name: 'phone_number',
  placeholder: '000-000-0000'
};
