import React from 'react';
import PropTypes from 'prop-types';

import Input from 'dpl/form/components/Input';
import { FORM_FIELD_VARIANT_TYPES } from 'dpl/shared/form/utils/constants';
import Icon from 'dpl/common/components/Icon';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';

import QuestionLayout from '../../components/QuestionLayout';

export default function NumberInputQuestion({
  name,
  title,
  subtitle,
  placeholder,
  variant
}) {
  const contextValue = useFormContext(name);
  const currentValue = Number(contextValue.formState[name]);

  function increment() {
    if (!currentValue) {
      return contextValue.setValue(name, 1);
    }
    return contextValue.setValue(name, currentValue + 1);
  }

  function decrement() {
    if (currentValue && currentValue > 0) {
      return contextValue.setValue(name, currentValue - 1);
    }
    return 0;
  }

  return (
    <div className="NumberInputQuestion">
      <QuestionLayout title={title} subtitle={subtitle}>
        <div className="flex justify-center align-items-center">
          <Input
            name={name}
            variant={variant}
            placeholder={placeholder}
            type="number"
          />
          <div className="flex flex-column justify-evenly">
            <button type="button" onClick={increment}>
              <Icon name="fat-arrow-up" />
            </button>
            <button type="button" onClick={decrement}>
              <Icon name="fat-arrow-down" />
            </button>
          </div>
        </div>
      </QuestionLayout>
    </div>
  );
}

NumberInputQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  placeholder: PropTypes.number,
  variant: PropTypes.oneOf(Object.values(FORM_FIELD_VARIANT_TYPES))
};

NumberInputQuestion.defaultProps = {
  title: null,
  subtitle: null,
  placeholder: null,
  variant: FORM_FIELD_VARIANT_TYPES.REGULAR
};
