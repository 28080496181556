import React from 'react';
import PropTypes from 'prop-types';

import Input from 'dpl/form/components/Input';
import { FORM_FIELD_VARIANT_TYPES } from 'dpl/shared/form/utils/constants';

export default function InlineTextInputQuestion({
  name,
  title,
  placeholder,
  variant
}) {
  return (
    <div className="InlineTextInputQuestion center">
      <div className="row items-center fw-medium tl">
        <div className="col-3 col-lg-2">
          <p>{title}</p>
        </div>
        <div className="col-9 col-lg-10">
          <Input name={name} variant={variant} placeholder={placeholder} />
        </div>
      </div>
    </div>
  );
}

InlineTextInputQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(FORM_FIELD_VARIANT_TYPES))
};

InlineTextInputQuestion.defaultProps = {
  placeholder: null,
  variant: FORM_FIELD_VARIANT_TYPES.REGULAR
};
