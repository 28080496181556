import React from 'react';
import PropTypes from 'prop-types';

import InstagramHandleInput from 'dpl/form/components/InstagramHandleInput';
import { FORM_FIELD_VARIANT_TYPES } from 'dpl/shared/form/utils/constants';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';

import QuestionLayout from '../../components/QuestionLayout';

export default function InstagramHandleInputQuestion({
  name,
  title,
  subtitle,
  placeholder,
  variant,
  type
}) {
  const {
    formFieldErrors,
    formFieldId,
    formFieldValue,
    handleFormFieldChange
  } = useFormContext(name);
  return (
    <div className="InstagramHandleInputQuestion">
      <QuestionLayout title={title} subtitle={subtitle}>
        <InstagramHandleInput
          name={name}
          variant={variant}
          placeholder={placeholder}
          type={type}
          errors={formFieldErrors}
          id={formFieldId}
          value={formFieldValue}
          onChange={handleFormFieldChange}
        />
      </QuestionLayout>
    </div>
  );
}

InstagramHandleInputQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(FORM_FIELD_VARIANT_TYPES)),
  type: PropTypes.string
};

InstagramHandleInputQuestion.defaultProps = {
  title: null,
  subtitle: null,
  placeholder: null,
  variant: FORM_FIELD_VARIANT_TYPES.REGULAR,
  type: 'text'
};
