import PropTypes from 'prop-types';
import React, { createRef, useEffect, useState } from 'react';
import { isEmpty } from 'validate.js';

import Input from 'dpl/common/design_system/Input';
import { ANALYTICS_EVENTS, ANALYTICS_VIEWS } from 'dpl/constants/analytics';
import useContactContext from 'dpl/contacts/hooks/useContactContext';
import {
  VERIFICATION_CHANNELS,
  VERIFICATION_CHANNEL_NAMES,
  VERIFICATION_CODE_LENGTH
} from 'dpl/contacts/utils/constants';
import ErrorWrapper from 'dpl/form/components/ErrorWrapper';
import { isNumeric } from 'dpl/shared/form/utils';
import { sendAnalyticsEvent } from 'dpl/util/analytics';

const SPECIAL_KEYS = {
  DELETE: 'Delete',
  BACKSPACE: 'Backspace',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight'
};

const INITIAL_INPUT_VALUES = new Array(VERIFICATION_CODE_LENGTH).fill('');
const SPECIAL_KEY_VALUES = Object.values(SPECIAL_KEYS);

const ALT_VERIFICATION_CTAS = {
  [VERIFICATION_CHANNELS.SMS]: 'get verified via email',
  [VERIFICATION_CHANNELS.EMAIL]: 'get verified via phone'
};

function isSameRecipient(recipientA, recipientB) {
  return (
    Object.keys(recipientA).length === Object.keys(recipientB).length &&
    Object.keys(recipientA).every(key => recipientA[key] === recipientB[key])
  );
}

function VerificationCodeInput({ value: initialCode, onChange }) {
  const inputRefs = INITIAL_INPUT_VALUES.map(createRef);
  const [inputValues, setInputValues] = useState(INITIAL_INPUT_VALUES);

  const code = inputValues.join('');

  function addInputValue(value, index) {
    setInputValues(
      inputValues.map((inputValue, currentIndex) =>
        currentIndex === index ? value : inputValue
      )
    );
  }

  function removeInputValue(index) {
    setInputValues(
      inputValues.map((inputValue, currentIndex) =>
        currentIndex === index ? '' : inputValue
      )
    );
  }

  function focusInput(index) {
    inputRefs[index]?.current?.focus();
  }

  function handleChange(e, index) {
    const { value } = e.target;

    if (!isNumeric(value)) return;

    addInputValue(value, index);

    if (VERIFICATION_CODE_LENGTH > index) {
      focusInput(index + 1);
    }
  }

  function handlePaste(e, index) {
    e.preventDefault();

    const pastedText = e.clipboardData.getData('text');

    const pastedValues = [...pastedText]
      .filter(isNumeric)
      .slice(0, VERIFICATION_CODE_LENGTH);

    setInputValues(
      inputValues.map((inputValue, inputIndex) => {
        return pastedValues[inputIndex - index] ?? inputValue;
      })
    );

    focusInput(
      Math.min(index + pastedValues.length, VERIFICATION_CODE_LENGTH - 1)
    );
  }

  function handleKeyDown(e, index) {
    if (SPECIAL_KEY_VALUES.includes(e.key)) {
      e.preventDefault();
    }

    if (isNumeric(e.key) && isNumeric(inputValues[index])) {
      e.preventDefault();
      addInputValue(e.key, index);
      focusInput(index + 1);
    } else if (e.key === SPECIAL_KEYS.BACKSPACE) {
      removeInputValue(index);
      focusInput(index - 1);
    } else if (e.key === SPECIAL_KEYS.DELETE) {
      removeInputValue(index);
    } else if (e.key === SPECIAL_KEYS.ARROW_LEFT) {
      focusInput(index - 1);
    } else if (e.key === SPECIAL_KEYS.ARROW_RIGHT) {
      focusInput(index + 1);
    }
  }

  useEffect(() => {
    onChange(code);
  }, [code]);

  useEffect(() => {
    // Reset input values when initial code is cleared
    if (isEmpty(initialCode)) {
      setInputValues(INITIAL_INPUT_VALUES);
    }
  }, [initialCode]);

  return (
    <div className="VerificationCodeInput flex justify-center gap-8">
      {inputValues.map((value, index) => (
        <Input
          key={index}
          ref={inputRefs[index]}
          className="tc no-select ph0"
          // Using a tel input as an alternative to number, so that
          // the numeric keypad is shown for mobile users
          type="tel"
          maxLength={1}
          value={value}
          onChange={e => handleChange(e, index)}
          onKeyDown={e => handleKeyDown(e, index)}
          onPaste={e => handlePaste(e, index)}
          style={{
            width: 40,
            // Hide cursor (transparent color) if input is filled
            caretColor: value ? 'transparent' : null
          }}
        />
      ))}
    </div>
  );
}

VerificationCodeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

VerificationCodeInput.defaultProps = {
  value: ''
};

export default function VerificationCodeQuestion({
  recipient: initialRecipient,
  phone_number: phoneNumber,
  email,
  isCurrentSection
}) {
  const {
    verification: {
      recipient,
      code,
      error,
      isFirstCodeSent,
      setRecipient,
      setCode,
      sendCode,
      eventProps
    }
  } = useContactContext();

  const channelName = VERIFICATION_CHANNEL_NAMES[recipient.channel];

  function trackButtonClick(e) {
    sendAnalyticsEvent(ANALYTICS_EVENTS.BUTTON_CLICKED, {
      ...eventProps,
      view: ANALYTICS_VIEWS.CONTACT_QUIZ,
      button_cta: e.target.textContent
    });
  }

  function handleCodeRequest(e) {
    // Send analytics event if button was clicked by user
    if (!isEmpty(e?.target)) trackButtonClick(e);

    if (isCurrentSection && !isEmpty(recipient)) {
      sendCode(recipient);
    }
  }

  function toggleRecipient(e) {
    // Send analytics event if button was clicked by user
    if (!isEmpty(e?.target)) trackButtonClick(e);

    if (recipient.channel === VERIFICATION_CHANNELS.SMS) {
      setRecipient({
        channel: VERIFICATION_CHANNELS.EMAIL,
        email
      });
    } else if (recipient.channel === VERIFICATION_CHANNELS.EMAIL) {
      setRecipient({
        channel: VERIFICATION_CHANNELS.SMS,
        phone_number: phoneNumber
      });
    }
  }

  // Send verification code when recipient changes
  useEffect(handleCodeRequest, [recipient]);

  // Send verification code when section is active
  useEffect(() => {
    if (isCurrentSection && !isFirstCodeSent) {
      handleCodeRequest();
    }
  }, [isCurrentSection]);

  // Use recipient returned by quiz API if it's different
  useEffect(() => {
    if (!isSameRecipient(initialRecipient, recipient)) {
      setRecipient(initialRecipient);
    }
  }, [initialRecipient]);

  return (
    <div className="VerificationCodeQuestion QuestionLayout tc">
      <div className="mb8 mb14-md tc">
        <h3 className="f4 fw-medium f5-md">Verify your {channelName}</h3>
        <p className="mt2 mt4-md font-16 secondary">
          We sent you a verification code to your {channelName}.
        </p>
      </div>
      <ErrorWrapper errors={[error]} isShown>
        <VerificationCodeInput value={code} onChange={setCode} />
      </ErrorWrapper>
      <p className="font-16 secondary mt6 tc">
        Didn’t receive a code?{' '}
        <button type="button" className="underline" onClick={handleCodeRequest}>
          Resend code
        </button>
        {phoneNumber && (
          <>
            {' or '}
            <br />
            <button
              type="button"
              className="underline"
              onClick={toggleRecipient}
            >
              {ALT_VERIFICATION_CTAS[recipient.channel]}
            </button>
          </>
        )}
      </p>
    </div>
  );
}

VerificationCodeQuestion.propTypes = {
  phone_number: PropTypes.string,
  email: PropTypes.string.isRequired,
  isCurrentSection: PropTypes.bool.isRequired,
  recipient: PropTypes.shape({
    channel: PropTypes.oneOf(Object.values(VERIFICATION_CHANNELS)),
    email: PropTypes.string,
    phone_number: PropTypes.string
  }).isRequired
};

VerificationCodeQuestion.defaultProps = {
  phone_number: null
};
