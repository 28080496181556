import React from 'react';

import SectionLayout from '../../components/SectionLayout';

export const SECTION_CLASSES = 'min-h-100 flex justify-center items-center';

export default function Section(props) {
  return (
    <div className={SECTION_CLASSES}>
      <SectionLayout {...props} />
    </div>
  );
}
