import React from 'react';
import PropTypes from 'prop-types';

import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import Checkbox from 'dpl/common/design_system/Checkbox';

export default function CheckboxQuestionV2({ name, title }) {
  const { setValue, formFieldValue } = useFormContext(name);

  return (
    <div className="CheckboxQuestionV2 mb4">
      <div className="CheckboxQuestionV2__content flex">
        <Checkbox
          id={name}
          name={name}
          value=""
          checked={Boolean(formFieldValue)}
          onChange={() => {
            setValue(name, !formFieldValue);
          }}
        />
        <label htmlFor={name} className="pl2 secondary font-16 self-center">
          {title}
        </label>
      </div>
    </div>
  );
}

CheckboxQuestionV2.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
