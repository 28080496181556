export const ICON_NAMES = [
  'caretaker',
  'dogpark',
  'gender-any',
  'gender-female',
  'gender-male',
  'home-apartment',
  'home-house',
  'home-townhouse',
  'locationmarker',
  'lock',
  'mailbox',
  'pet-birds',
  'pet-cats',
  'pet-dogs',
  'pet-none',
  'pram',
  'questionmark',
  'time-1y',
  'time-1y-plus',
  'time-3m',
  'zipcode',
  'cake'
];
