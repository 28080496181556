import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function FullPageQuizTextInput({
  Tag,
  className,
  labelText,
  newDesignSystemStyles,
  hasErrors,
  ...htmlProps
}) {
  return (
    <>
      {labelText && (
        <span className="fl mb2 font-12 fw-normal secondary">{labelText}</span>
      )}
      <Tag
        className={classnames(
          'FullPageQuizTextInput w-100 input-reset ph4 br2 ba b--moon-gray',
          {
            'pv4 pv5-md fw-medium': !newDesignSystemStyles,
            'font-16 pv3 pv4-md secondary': newDesignSystemStyles,
            FullPageQuizTextInput__textarea: Tag === 'textarea',
            FullPageQuizTextInput__input: Tag === 'input',
            'b--rubber': hasErrors && newDesignSystemStyles
          },
          className
        )}
        {...htmlProps}
      />
    </>
  );
}

FullPageQuizTextInput.propTypes = {
  Tag: PropTypes.oneOf(['input', 'textarea']),
  className: PropTypes.string,
  labelText: PropTypes.string,
  newDesignSystemStyles: PropTypes.bool,
  hasErrors: PropTypes.bool
};

FullPageQuizTextInput.defaultProps = {
  Tag: 'input',
  className: null,
  labelText: null,
  newDesignSystemStyles: false,
  hasErrors: false
};
