import React from 'react';
import PropTypes from 'prop-types';

import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import OptionGroup from 'dpl/quiz/option_group/components/OptionGroup';
import OptionGroupOption from 'dpl/quiz/option_group/components/OptionGroupOption';
import { OPTION_GROUP_VARIANTS } from 'dpl/quiz/option_group/utils/constants';

export default function OptionGroupQuestion({
  name,
  options,
  variant,
  multiple
}) {
  const { addToFormState, formFieldValue } = useFormContext(name);

  function isOptionSelected(opt) {
    if (multiple) {
      return (formFieldValue ?? []).includes(opt.value);
    }

    return opt.value === formFieldValue;
  }

  function onSelectOption(value) {
    if (value === '') {
      // No preference value is an empty string, and should reset all other selected values
      addToFormState(name, ['']);
    } else if (multiple) {
      // Current values with No preference value removed
      const currentValues = [...(formFieldValue ?? [])].filter(v => v !== '');

      if (currentValues.includes(value)) {
        currentValues.splice(currentValues.indexOf(value), 1);
      } else {
        currentValues.push(value);
      }
      addToFormState(name, currentValues);
    } else {
      addToFormState(name, value);
    }
  }

  const inputType = multiple ? 'checkbox' : 'radio';

  return (
    <div className="QuestionLayout">
      <OptionGroup variant={variant}>
        {options.map(option => (
          <OptionGroupOption
            variant={variant}
            type={inputType}
            key={option.value}
            inputName={name}
            title={option.label}
            value={option.value}
            onSelect={() => onSelectOption(option.value)}
            isSelected={isOptionSelected(option)}
            subtitle={option.subtitle}
          />
        ))}
      </OptionGroup>
    </div>
  );
}

OptionGroupQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      subtitle: PropTypes.string
    })
  ).isRequired,
  variant: PropTypes.oneOf(Object.values(OPTION_GROUP_VARIANTS)).isRequired,
  multiple: PropTypes.bool
};

OptionGroupQuestion.defaultProps = {
  multiple: false
};
