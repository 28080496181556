import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'dpl/common/components/DatePicker';
import QuestionLayout from 'dpl/quiz/components/QuestionLayout';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';

export default function DateQuestion({
  name,
  id,
  title,
  subtitle,
  ...datePickerProps
}) {
  const { formFieldValue, addToFormState } = useFormContext(name);
  return (
    <div className="DateQuestion">
      <QuestionLayout title={title} subtitle={subtitle}>
        <DatePicker
          displayCalendarIcon
          {...datePickerProps}
          name={name}
          inputId={id}
          date={formFieldValue}
          onChange={date => addToFormState(name, date)}
        />
      </QuestionLayout>
    </div>
  );
}

DateQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  id: PropTypes.string.isRequired
};

DateQuestion.defaultProps = {
  subtitle: ''
};
