import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FullPageQuizTextInput from 'dpl/components/FullPageQuiz/FullPageQuizTextInput';
import ErrorWrapper from 'dpl/form/components/ErrorWrapper';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';

export default function InlineTextInputQuestionV2({
  name,
  title,
  placeholder,
  type,
  className,
  pattern
}) {
  const { handleFormFieldChange, formFieldValue, formFieldErrors } =
    useFormContext(name);

  return (
    <div className={classnames('InlineTextInputQuestionV2 center', className)}>
      <div className="items-center fw-medium tl">
        <ErrorWrapper errors={formFieldErrors}>
          <FullPageQuizTextInput
            type={type}
            id={name}
            name={name}
            placeholder={placeholder}
            labelText={title}
            value={formFieldValue}
            onChange={handleFormFieldChange}
            newDesignSystemStyles
            pattern={pattern}
          />
        </ErrorWrapper>
      </div>
    </div>
  );
}

InlineTextInputQuestionV2.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password']),
  className: PropTypes.string,
  pattern: PropTypes.string
};

InlineTextInputQuestionV2.defaultProps = {
  placeholder: null,
  type: 'text',
  className: '',
  pattern: null
};
