import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import invariant from 'invariant';

import { RadioButtonInput } from 'dpl/form/components/RadioButton';
import { CheckboxInput } from 'dpl/form/components/Checkbox';

export default function ListOption({
  children,
  value,
  onSelect,
  type,
  inputName,
  isSelected
}) {
  const htmlId = `${inputName}-${value}`;

  if (!['radio', 'checkbox'].includes(type)) {
    return invariant(`Invalid type ${type} passed to ListOption`);
  }

  return (
    <div
      className={classnames(
        'QuizOptionGroupListOption col-sm-auto b--black-20 bb'
      )}
    >
      <label
        className="w-100 pv5 flex items-center flex-md-row-reverse"
        htmlFor={htmlId}
      >
        {children}
        {(() => {
          switch (type) {
            case 'radio':
              return (
                <RadioButtonInput
                  checked={isSelected}
                  id={htmlId}
                  name={inputName}
                  onChange={onSelect}
                  value={value}
                />
              );
            case 'checkbox':
              return (
                <CheckboxInput
                  checked={isSelected}
                  id={htmlId}
                  name={inputName}
                  onChange={onSelect}
                  value={value}
                />
              );
            default:
              return null;
          }
        })()}
      </label>
    </div>
  );
}

ListOption.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  type: PropTypes.oneOf(['radio', 'checkbox']),
  inputName: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool
};

ListOption.defaultProps = {
  type: 'radio',
  isSelected: false
};
