import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ICON_NAMES as AdoptionApplicationIconNames } from 'dpl/quiz/adoption_application/utils/constants';
import { ICON_NAMES as ScreeningQuizIconNames } from 'dpl/quiz/screening_quiz/utils/constants';

export default function Icon({ className, name }) {
  return (
    <svg
      className={classnames('QuizIcon w-100', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <use xlinkHref={`#icon-fullpagequiz-${name}`} />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
  // less about validation and more about just letting us know what's available!
  name: PropTypes.oneOf([
    ...AdoptionApplicationIconNames,
    ...ScreeningQuizIconNames
  ]).isRequired
};

Icon.defaultProps = {
  className: null
};
