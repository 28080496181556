export const VERIFICATION_CODE_LENGTH = 6;

export const VERIFICATION_CHANNELS = {
  EMAIL: 'email',
  SMS: 'sms'
};

export const VERIFICATION_CHANNEL_NAMES = {
  [VERIFICATION_CHANNELS.EMAIL]: 'email',
  [VERIFICATION_CHANNELS.SMS]: 'phone number'
};
