import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { OPTION_GROUP_VARIANTS } from 'dpl/quiz/option_group/utils/constants';
import { isLessThanBreakpoint } from 'dpl/util/grid';

import ListOptionWithTitle from './ListOptionWithTitle';
import DefaultOption from './DefaultOption';
import IconOption from './IconOption';
import PillOption from './PillOption';

export default function OptionGroupOption({
  variant,
  isListOnMobile,
  ...optionProps
}) {
  const isIconVariant = variant === OPTION_GROUP_VARIANTS.ICON;
  const isPillVariant = variant === OPTION_GROUP_VARIANTS.PILL;
  const isListVariant =
    variant === OPTION_GROUP_VARIANTS.LIST ||
    (!isIconVariant &&
      !isPillVariant &&
      isListOnMobile &&
      isLessThanBreakpoint('md'));

  return (
    <li
      className={classnames('QuizOptionGroupOption', {
        'col-sm-auto col-6': isIconVariant
      })}
    >
      {(() => {
        if (isListVariant) {
          return <ListOptionWithTitle {...optionProps} />;
        }
        if (isIconVariant) {
          return <IconOption {...optionProps} />;
        }

        if (isPillVariant) {
          return <PillOption {...optionProps} />;
        }

        return <DefaultOption {...optionProps} variant={variant} />;
      })()}
    </li>
  );
}

OptionGroupOption.propTypes = {
  variant: PropTypes.oneOf(Object.values(OPTION_GROUP_VARIANTS)),
  isListOnMobile: PropTypes.bool
};

OptionGroupOption.defaultProps = {
  variant: OPTION_GROUP_VARIANTS.CIRCLE,
  isListOnMobile: true
};
