import invariant from 'invariant';

import * as SECTION_COMPONENTS from '../sections/components';
import * as QUESTION_COMPONENTS from '../questions/components';

export function defaultGetSectionComponent(section) {
  const componentName = section.component_name || 'Section';
  const SectionComponent = SECTION_COMPONENTS[componentName];

  invariant(
    SectionComponent,
    `${componentName} is not a valid section component.`
  );

  return SectionComponent;
}

export function defaultGetQuestionComponent(question) {
  const QuestionComponent = QUESTION_COMPONENTS[question.component_name];

  invariant(
    QuestionComponent,
    `${question.component_name} is not a valid question component.`
  );

  return QuestionComponent;
}

export const SECTION_TYPES = {
  INTERSTICE: 'interstice',
  QUESTION: 'question'
};
