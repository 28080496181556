import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import OptionGroup from 'dpl/quiz/option_group/components/OptionGroup';
import OptionGroupOption from 'dpl/quiz/option_group/components/OptionGroupOption';
import { OPTION_GROUP_VARIANTS } from 'dpl/quiz/option_group/utils/constants';
import { get } from 'dpl/shared/utils/object';
import Textarea from 'dpl/form/components/Textarea';

import QuestionLayout from '../../components/QuestionLayout';

export default function CheckboxListWithTextareaQuestion({
  name,
  title,
  subtitle,
  options,
  placeholder
}) {
  const { setValue, formFieldValue } = useFormContext(name);
  // this is used for questions that have a checklist of options
  // plus an open text field for an other value
  // the formFieldValue looks like => { values: [], description: '' }
  const selectedOptions = new Set(get(formFieldValue, 'values', []));

  function clearFormFieldValueIfNeeded() {
    const values = get(formFieldValue, 'values', []);
    const description = get(formFieldValue, 'description', '');

    if (formFieldValue && values.length === 0 && description.trim() === '') {
      // since this question type is an if values or description this checks if both values are
      // empty, and if so will reset the form field value to null to perform correct validations
      setValue(name, null);
    }
  }

  function handleOptionSelect(e) {
    const { value, checked } = e.target;

    if (checked) {
      selectedOptions.add(value);
    } else {
      selectedOptions.delete(value);
    }

    setValue(`${name}.values`, [...selectedOptions]);
  }

  useEffect(() => {
    clearFormFieldValueIfNeeded();
  }, [formFieldValue?.description, formFieldValue?.values]);

  return (
    <div className="CheckboxListWithTextareaQuestion">
      <QuestionLayout title={title} subtitle={subtitle}>
        <div className="mb3">
          <OptionGroup variant={OPTION_GROUP_VARIANTS.LIST}>
            {options.map(o => (
              <OptionGroupOption
                type="checkbox"
                key={o.value}
                inputName={`${name}.values`}
                isSelected={selectedOptions.has(o.value)}
                title={o.label}
                subtitle={o.description}
                icon={o.icon}
                value={o.value}
                onSelect={handleOptionSelect}
              />
            ))}
          </OptionGroup>
        </div>
        <Textarea
          name={`${name}.description`}
          placeholder={placeholder}
          noResize
        />
      </QuestionLayout>
    </div>
  );
}

CheckboxListWithTextareaQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string
};

CheckboxListWithTextareaQuestion.defaultProps = {
  title: null,
  subtitle: null,
  placeholder: null
};
