import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { OPTION_GROUP_VARIANTS } from 'dpl/quiz/option_group/utils/constants';
import { isLessThanBreakpoint } from 'dpl/util/grid';

export default function OptionGroup({ children, variant, isListOnMobile }) {
  const isCircular = variant === OPTION_GROUP_VARIANTS.CIRCLE;
  const isIconVariant = variant === OPTION_GROUP_VARIANTS.ICON;
  const isPillVariant = variant === OPTION_GROUP_VARIANTS.PILL;
  const isListVariant =
    variant === OPTION_GROUP_VARIANTS.LIST ||
    (!isIconVariant &&
      !isPillVariant &&
      isListOnMobile &&
      isLessThanBreakpoint('md'));

  return (
    <div
      className={classnames('QuizOptionGroup', {
        'QuizOptionGroup__list col ba b--black-20':
          isListVariant && !isPillVariant
      })}
      data-test-id={
        isListVariant ? 'QuizOptionGroup-List' : 'QuizOptionGroup-Nonlist'
      }
    >
      <ul
        className={classnames('tc justify-center', {
          'flex-lg-nowrap': isListVariant && !isPillVariant,
          'flex flex-wrap': !isListVariant,
          // compensate for internal margins
          nb6:
            !isListVariant && !isPillVariant && (isIconVariant || !isCircular),
          nb3: !isListVariant && isCircular
        })}
      >
        {React.Children.toArray(children)
          .filter(Boolean)
          .map(child => {
            if (typeof child.type === 'string') {
              return child;
            }

            return React.cloneElement(child, { variant, isListOnMobile });
          })}
      </ul>
    </div>
  );
}

OptionGroup.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(Object.values(OPTION_GROUP_VARIANTS)),
  isListOnMobile: PropTypes.bool
};

OptionGroup.defaultProps = {
  variant: OPTION_GROUP_VARIANTS.CIRCLE,
  isListOnMobile: true
};
