import React from 'react';
import PropTypes from 'prop-types';

import Select from 'dpl/form/components/Select';
import { FORM_FIELD_VARIANT_TYPES } from 'dpl/shared/form/utils/constants';

import QuestionLayout from '../../components/QuestionLayout';

export default function SelectQuestion({
  name,
  title,
  subtitle,
  options,
  variant
}) {
  return (
    <div className="SelectQuestion">
      <QuestionLayout title={title} subtitle={subtitle}>
        <Select
          name={name}
          options={options.map(o => ({ title: o.label, value: o.value }))}
          variant={variant}
        />
      </QuestionLayout>
    </div>
  );
}

SelectQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  variant: PropTypes.oneOf(Object.values(FORM_FIELD_VARIANT_TYPES))
};

SelectQuestion.defaultProps = {
  title: null,
  subtitle: null,
  variant: FORM_FIELD_VARIANT_TYPES.REGULAR
};
